<template>
    <div class="select-time d-flex flex-column mb-4">
        <ValidationProvider 
            :rules="rules" 
            :name="$attrs.name" 
            :vid="name"
            v-slot="{ valid, errors }"
            tag="div"
        >
        <label 
        >   
            {{label}} 
            <span v-if="rules && label" class="label-red">*</span>
        </label>
        <div>
            <VueTimepicker
                input-class="select-time--wrapper"
                :placeholder="placeholder"
                v-model="value"
                :state="errors[0] ? false : (valid ? true : null)"
                @input="$emit('selectedTime', value)"
                close-on-complete
                input-width="100%"
            />
        </div>
        <!-- error heandling -->            
        <!-- error heandling -->            
        <span class="input-error" v-if="errors[0]" :class="{'active-error': errors[0]}">{{ errors[0] }}</span>
        </ValidationProvider>
    </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
export default {
    components: {
        ValidationProvider,
        VueTimepicker
    },
    props: {
        label: {
            required: false,
            type: String,
            default: ''
        },
        placeholder: {
            required: false,
            type: String,
            default: 'Válassz'
        },
        rules: {
            required: false,
            type: [Object, String],
            default: ''
        },
        name: {
            required: false,
            type: String,
        }
    },
    data () {
        return {
            value: null
        }
    }
}
</script>