<template>
    <div id="nyertesek" class="winners pb-0">
        <div class="container-xl">
            <h2 class="font-header display-2 fw-900 text-light text-uppercase text-center mb-5 mt-0 mt-md-5">
                Nyertesek
            </h2>
            
            <div class="row">
                <div class="col-2 d-none d-xxl-block"></div>
                <div class="col-12 col-xxl-8 mb-0 mb-md-5 text-center">
                    <p>A heti nyeremények nyertesei az adott játékhét utáni első munkanapon kerülnek kiértesítésre a lebonyolító által küldött e-mail üzenetben, míg a napi nyerteseket a következő munkanapon értesítjük. </p>
                    <p>A nyerteseket előre kisorsolt nyerőidőpontok alapján állapítjuk meg.</p>
                </div>        
            </div>

            <div class="row">
                <!-- DROPDOWN BTN -->
                <div class="col-12 col-lg-8 offset-lg-2">
                    <div class="d-md-flex justify-content-center align-items-top">
                        <div 
                            v-for="win in wins"
                            :key="'winners' + win.id"
                            class="winners-collapse d-flex flex-column justify-content-start align-items-center mb-5 mb-xl-0"
                            data-bs-toggle="collapse" 
                            :data-bs-target="'#winner-' + win.id" 
                            aria-expanded="false" 
                            aria-controls="collapseExample"
                        >
                            <img 
                                :src="`/imgs/${win.img}`" 
                                class="img-fluid winners-card-img my-4"
                                alt="nyeremenyek">
                            
                            <!--<div class="winners-collapse-button mt-3">
                                <img src="/imgs/winners-arrow.svg" alt="winners-arrow">
                            </div>-->                 

                            <!-- DROPDOWN LIST -->
                            <!--<div 
                                class="winner-collapse-content collapse mt-3" 
                                :id="'winner-' + win.id"                       
                            >
                                <div  v-if="win.winners.length>0">
                                    <p 
                                        v-for="(winner, idx) in win.winners"
                                        :key="'name' + idx"
                                        class="mb-1 text-center"
                                    >
                                        {{winner.name}} - {{winner.winning_time}}
                                    </p>
                                </div>
                                <div v-else>Hamarosan..</div>
                            </div>  -->
                                              
                        </div>
                    </div>
                    <p class="text-center">A kisorsolt nyertesek részére a nyeremények átadása megtörtént!</p>
                </div>
            </div>

            <div class="row">
                <div class="col-12 my-4 my-md-5">
                    <div class="d-flex flex-column">
                        <p class="text-center mb-5 fs-12">A nyeremények képe illusztráció.</p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            winnersAll:[],
            wins: [                
                {
                    id: 1,
                    name: 'napi nyeremény',
                    type:'napi',
                    line1: '',
                    line2: '',
                    line3: '',
                    winners: [],
                    img:"napi-nyeremeny-dark.png"
                },                
                {
                    id: 2,
                    name: 'heti',
                    type:'heti',
                    line1: '',
                    line2: '',
                    line3: '',
                    winners: [],
                    img:'heti-nyeremeny-dark.png'
                },
            ]
        }
    },
    methods: {
        // imgUrl(nr){
        //     return '/imgs/win-' + nr + '.png'
        // },
        selectWinners(){
            let _this = this
            this.wins.forEach( item => {                
                let winTypeWinners = _this.winnersAll.filter( element => element.type == item.type )
                console.log('winTypeWinners',winTypeWinners)
                if (winTypeWinners)
                    item.winners = winTypeWinners
            })
        }
    },
    created(){
        this.get('info/winners',{})
        .then((resp) => {
          this.winnersAll = resp.data.winners
          this.selectWinners()
        }).catch((err) => {
            console.log(err)
        })
    }
    
}
</script>