<template>
    <div id="termekek" class="products py-5 position-relative">
        <div class="container-xl">                    
            <div class="row">                
                <div class="col-md-10 offset-md-1 d-flex flex-column text-center">
                    <div class="products-container">
                        <h2 class="font-header display-2 fw-900 text-light text-uppercase text-center mb-5 mt-0 ">
                            Játékban részt vevő termékek
                        </h2>
                        <div class="products-container__inner">
                            <img src="/imgs/lipton-logo.png" class="fluid-img mb-3" alt="Lipton" style="max-width:170px" />
                            <p>Magyarországon valamennyi Auchan áruházban vagy az Auchan Online Áruházában (<a href="https://online.auchan.hu" target="_blank"> https://online.auchan.hu</a>) vásárolt a Játékban részt vevő Lipton termékek. A választék áruházanként eltérő lehet.</p>
                            <div class="text-center mt-4">
                                <a class="btn btn-primary mx-auto" href="/pdf/resztvevo_termekek.pdf" target="_blank" @click="setGtm('Részt vevő termékek listája')">
                                    Részt vevő termékek <span class="d-none d-md-block">listája</span>
                                </a>  
                            </div>
                        </div>
                    </div>
                </div>        
            </div>         
        </div>
        <!-- decor images -->
        <img src="/imgs/jatek_menete_bal.png" class="rules__decor decor--left d-none d-xl-block" />
        <img src="/imgs/jatek_menete_jobb.png" class="rules__decor decor--right d-none d-xl-block" />
    </div>
</template>

<script>
export default {
    methods: {   
        setGtm(btnName){
            this.GTtrackGA4({
                'event' : 'event',
                'category': 'jatekban_reszt_vevo_termekek',
                'action': 'gomb_kattintas',
                'label': btnName,                
                'clicked_text':btnName,
                'action_type': 'gomb_kattintas'
            })
        }
    }
}
</script>