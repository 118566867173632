<template>
    <div class="dropdown w-100 mb-4">
        <ValidationProvider 
            :rules="rules" 
            :name="$attrs.name" 
            :vid="name" 
            v-slot="{ valid, errors }"
            tag="div"
        >
        
            <label 
                v-if="label"
                :for="name"
            >   
                {{label}} 
                <span v-if="rules" 
                    class="label-red"
                >
                    *
                </span>
            </label>
            <input type="hidden" v-model="value" />
            <button
                class="btn btn-dropdown dropdown-toggle text-left"
                type="button" 
                :id="name"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                :state="errors[0] ? false : (valid ? true : null)"
            >
                <p 
                    v-if="placeh"
                    class="mb-0">{{placeh}}
                </p>
                <p 
                    v-else
                    class="mb-0">
                    <span v-if="value.value">{{value.value}}</span>
                    <span v-else>{{value}}</span>
                </p>
            </button>
            <ul 
                class="dropdown-menu" 
                :aria-labelledby="name" 
                role="menu"
            >
                <li 
                    class="dropdown-item"
                    v-for="(option, ind) in options" 
                    :key="ind"
                    @click="selectItem(option)"
                >
                    <span v-if="option.value">{{option.value}}</span>
                    <span v-else>{{option}}</span>
                </li>
            </ul>
            <!-- error heandling -->            
            <span class="input-error" v-if="errors[0]" :class="{'active-error': errors[0]}">{{ errors[0] }}</span>
        </ValidationProvider>
    </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
export default {
    components: {
            ValidationProvider
    },
    props: {
        label: {
            required: false,
            type: String,
            default: ''
        },
        name: {
            required: true,
            type: String,
        },
        placeholder: {
            required: false,
            type: String,
            default: 'Válassz'
        },
        options: {
            required: true,
            type: Array,
        },
        rules: {
            required: false,
            type: [Object, String],
            default: ""
        }
    },
    data () {
        return {
            value: null,
            placeh: this.placeholder
        }
    },
    methods: {
        selectItem(option) {
            this.placeh = null
            if(this.value != option){
                this.value = option
                this.$emit('selectedItem', this.value)
            }
        }
    }   
}
</script>