<template>
    <div id="jatekszabaly" class="rules position-relative">
        <div class="container-xl">

            <div class="row">
                <!-- TABOK -->
                <div class="col-md-10 offset-md-1 d-flex flex-column ">
                    <div class="rules-container">
                        <h2 class="font-header text-uppercase display-2 fw-900 text-light text-center mb-2 mb-md-5 mt-0 mt-md-5">
                            Játék menete
                        </h2>
                        <ul 
                            class="rules-buttons nav nav-pills d-flex justify-content-between" 
                            id="pills-tab" 
                            role="tablist"
                        >
                            <li 
                                v-for="tab in tabs"
                                :key="'tab-button-' + tab.id" 
                                role="presentation"
                                class="d-flex justify-content-center">
                                <div 
                                    class="rules-tab-button d-flex align-items-center" 
                                    :class="tab.id == 1 ? 'active' : ''"
                                    data-bs-toggle="pill"
                                    :data-bs-target="'#pills-' + tab.id"
                                    role="tab"
                                    aria-selected="true"
                                >
                                    <div class="frules-tab-button-content d-flex flex-column">
                                        <div class="d-flex justify-content-between">
                                            <div class="rules-img d-flex justify-content-center align-items-center">
                                                <img 
                                                    :src="tab.img" 
                                                    class="img-fluid" 
                                                    alt="szabályok"
                                                >
                                            </div>
                                        </div>
                                        <p 
                                            class="rules-tab-title text-dark fw-900 text-uppercase text-center mt-4 mb-5"
                                        >
                                            {{tab.name}}
                                        </p>
                                        
                                    </div>
                                    
                                </div>
                            </li>
                        </ul>
                        
                        <div 
                            class="tab-content flex-1" 
                            id="pills-tabContent">
                            <div
                                v-for="tab in tabs"
                                :key="'tab-content-' + tab.id" 
                                class="tab-pane fade"
                                :class="tab.id == 1 ? 'show active' : ''" 
                                :id="'pills-' + tab.id" 
                                role="tabpanel" 
                                aria-labelledby="'pills-'+ tab.id + '-tab'"
                            >
                                <div 
                                    class="rules-tab-content"
                                    v-html="tab.content"
                                >  
                                </div>
                                
                            </div>
                        </div>
                        <div class="fw-900 text-uppercase text-center mt-5 text-dark rules__title">Jó játékot, sok szerencsét kívánunk!</div>     
                    </div>         
                </div> <!-- TABOK VÉGE -->
                
            </div> <!-- end of ROW -->
                
        </div>
        <!-- decor images -->
        <img src="/imgs/jatek_menete_bal.png" class="rules__decor decor--left d-none d-xl-block" />
        <img src="/imgs/jatek_menete_jobb.png" class="rules__decor decor--right d-none d-xl-block" />
    </div>
</template>

<script>
export default {
    data () {
        return {
            tabs: [
                {
                    id: 1,
                    name: "Vásárolj",
                    img: "/imgs/rules-1.png",
                    content:
                    ` Vásárolj egyszerre <strong>legalább 500 Ft értékben</strong> a promócióban részt vevő <strong class="text-primary">Lipton terméket</strong> az Auchan áruházakban, vagy az <strong>Auchan Online Áruházban</strong> (a <a class="text-primary fw-bold" href="https://online.auchan.hu" target="_blank"> https://online.auchan.hu</a> oldalon keresztül), hogy részt vehess a játékban.<br/><br/>A nyereményjáték időtartama:<strong> 2022. július 07. - augusztus 03.</strong>`

                },
                {
                    id: 2,
                    name: "Regisztrálj",
                    img: "/imgs/rules-2.png",
                    content:
                    `Regisztrálj, majd töltsd fel a vásárlást igazoló bizonylat adatait és játssz a nyereményekért!<br/><br/>Ne feledd, minél <strong>többször vásárolsz</strong> a promócióban részt vevő Lipton termékekből egyidejűleg legalább 500 Ft értékben, annyiszor <strong>több esélyed van a nyerésre</strong>!`
                },
                {
                    id: 3,
                    name: "Nyerj",
                    img: "/imgs/rules-3.png",
                    content:
                    `<strong>A vásárlást igazoló eredeti bizonylat sértetlen</strong>, ép állapotban történő megőrzése <strong>2022. szeptember 03-ig</strong> szükséges.<br/><br/>A játék ideje alatt előre meghatározott <strong>nyerőidőpontokban</strong> megnyerhető:
                    <ul class="ms-0">
                        <li class="fw-bold">hetente 1 db Hőlégballon túra</li>
                        <li class="fw-bold">naponta 1 db Strandtáska+Törölköző szett</li>
                    </ul>`
                }
            ]
        }
    },
    methods:{
        setGtm(btnName){
            this.GTtrackGA4({
                'event' : 'event',
                'category': 'jatek_menete',
                'action': 'gomb_kattintas',
                'label': btnName,                
                'clicked_text':btnName,
                'action_type': 'gomb_kattintas'
            })
        }
    }
}
</script>