<template>
    <div class="hero" id="hero">
        <div class="hero-gradient"></div>
        <div class="hero-container container-xl">
            <!-- NYEREMÉNYEK -->
            <div class="hero-top">
                <img src="/imgs/hero-title.png" alt="Oszd meg" class="img-fluid hero__title" />
                <div class="fs-3 fw-bold text-uppercase text-center my-4 hero__date d-md-none">
                        2022. július 07. -<br/> augusztus 03.
                </div>
                <div class="hero__wins d-flex flex-wrap flex-md-nowrap">
                    <div class="win">
                        <img src="/imgs/napi-nyeremeny.png" alt="Napi nyeremény" class="img-fluid" />
                        <!-- <img src="/imgs/win-1.png" alt="Napi nyeremény" class="img-fluid" />
                        <div class="win__type text-primary text-uppercase fw-bold">NAPI 1x</div>
                        <div class="win__name text-white">strandtáska +<br/> törölköző</div> -->
                    </div>
                    <div class="win">
                        <img src="/imgs/heti-nyeremeny.png" alt="Napi nyeremény" class="img-fluid" />
                        <!-- <img src="/imgs/win-2.png" alt="Heti nyeremény" class="img-fluid" />
                        <div class="win__type text-primary text-uppercase fw-bold">heti 1x</div>
                        <div class="win__name text-white">hőlégballon túra</div> -->
                    </div>
                </div>
            </div>

            <!-- NYEREMÉNYEK -->
            <div class="row">
                <div class="col-12">
                    <p class="fs-3 fw-bold text-uppercase text-center my-4 hero__date d-none d-md-block">
                        2022. július 07. - augusztus 03.
                    </p>
                </div>
            </div>

            <!-- GOMBOK -->
            <div class="mt-4">
                <div class="d-flex flex-column flex-md-row justify-content-center">
                    <a class="btn btn-outline-primary me-0 me-md-4 order-1 order-md-0" href="/pdf/jatekszabalyzat.pdf" target="_blank" @click="setGtm('Játékszabályzat')">
                        Játékszabályzat
                    </a>                            
                    <button
                        class="btn btn-primary mb-4 mb-md-0 order-0 order-md-1 text-white"
                        v-scroll-to="!$store.state.user ? '#regisztracio' : '#palyazat'"
                        @click="setGtm('Játszani szeretnék')"
                    >
                        Játszani szeretnék
                    </button>
                </div> 
            </div>            

            <div class="row pt-2 pb-5">
                <div class="col-1 d-none d-xxl-block"></div>
                <div class="col-12">
                    <p class="text-white text-center my-4 fs-12">
                        A nyeremények képe illusztráció.
                    </p>
                </div>
            </div>            
            <img src="/imgs/liptop-two-pack_2.png" class="img-fluid d-md-none w-100" /> 
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        imgUrl(nr){
            return '/imgs/decor-' + nr + '.png'
        },
        setGtm(btnName){
            this.GTtrackGA4({
                'event' : 'event',
                'category': 'jatekleiras',
                'action': 'gomb_kattintas',
                'label': btnName,                
                'clicked_text':btnName,
                'action_type': 'gomb_kattintas'
            })
        }
    }
}
</script>