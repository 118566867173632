<template>
    <ValidationProvider 
        :rules="rules" 
        name="choice" 
        v-slot="{ errors }"
        class="mb-4"
        tag="div"
    >
        <div class="custom-radio">
            <div class="content">
                <input 
                    type="radio" 
                    :name="`${id}_rd`" 
                    :id="`${id}_1`"
                    :value="firstValue"
                    v-model="choice"       
                    :class="[{'checked': choice == firstValue}]"
                    @input="$emit('input', $event.target.value)"
                >
                 <label :for="`${id}_1`" class="box first" >
                    <div class="plan">
                        <span class="circle"></span>
                        <span class="yearly">{{ firstLabel }}</span>
                    </div>
                </label>
                <input 
                    type="radio" 
                    :name="`${id}_rd`" 
                    :id="`${id}_2`"
                    :value="secondValue"
                    v-model="choice"                        
                    :class="[{'checked': choice == secondValue}]"                                                                      
                    @input="$emit('input', $event.target.value)"
                >
                
               
                
                <label :for="`${id}_2`" class="box second" >
                    <div class="plan">
                        <span class="circle"></span>
                        <span class="yearly">{{ secondLabel }}</span>
                    </div>
                </label>
            </div>
        </div>
        <span class="input-error" v-if="errors[0]" :class="{'active-error': errors[0]}">{{ errors[0] }}</span>
    </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
export default {
    components: {
        ValidationProvider
    },
    props: {
        id:{
            required:true,
            type:String
        },
        name: {
            required: true,
            type: String,
        },
        firstLabel: {
            required: true,
            type: String,
        },
        firstValue: {
            required: true,
            type: [String, Number]            
        },
        secondLabel: {
            required: true,
            type: String,
        },
        secondValue: {
            required: true,
            type: [String, Number]                     
        },
        rules: {
            required: false,
            type: [Object, String],
            default: ""
        },
        firstCheck:{
            required:false,
            type:String,
            default: null
        }
    },
    data () {
        return {
            choice: this.firstCheck ?? null            
        }
    },
    computed: {
        isFirstValue(){
            return this.choice == this.firstValue
        }
    }
}
</script>